import { Box } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import AllForms from './all-form-dashboard.component';
import TabBar from '../../../atoms/Tabs/TabBar';
import AllUserOnboardingBuilder from '../user-onboarding-build/all-user-onboarding-builder';

const ManageFormDashboard = (props) => {
  const { currentTab = 0 } = props;
  const navigate = useNavigate();

  const dataTabs = [
    {
      name: 'claims',
      label: 'Claims Builder',
      title: 'Claims Builder',
      hide: false,
      panel: (
        <Box mx={-3}>
          <AllForms />{' '}
        </Box>
      ),
    },
    {
      name: 'user-onboarding',
      label: 'User Onboarding Editor',
      title: 'User Onboarding Editor',
      hide: false,
      panel: (
        <Box mx={-3}>
          <AllUserOnboardingBuilder />,
        </Box>
      ),
    },
  ];

  const handleChange = (tabIndex) => {
    navigate(`/builder/${dataTabs[tabIndex].name}`);
  };
  const onSearchChange = (e, tabIndex) => {
    navigate(`/builder/${tabIndex.name}`);
  };

  return (
    <Box>
      <TabBar
        tabPanels={dataTabs}
        searchTabs
        disableCreate
        currentTab={currentTab}
        onTabChange={handleChange}
        onSearchChange={onSearchChange}
      />
    </Box>
  );
};

export default ManageFormDashboard;
