export const USER_ADD_CONFIG = 'USER_ADD_CONFIG';

export const USER_EDIT_MODAL_DATA = 'USER_EDIT_MODAL_DATA';

export const USER_DELETE_MODAL = 'USER_DELETE_MODAL';

export const USER_RESET_CONFIG = 'USER_RESET_CONFIG';

export const GET_CLAIM_USER_FORM_CONFIG_REQUEST = 'GET_CLAIM_USER_FORM_CONFIG_REQUEST';
export const GET_CLAIM_USER_FORM_CONFIG_SUCCESS = 'GET_CLAIM_USER_FORM_CONFIG_SUCCESS';
export const GET_CLAIM_USER_FORM_CONFIG_FAILURE = 'GET_CLAIM_USER_FORM_CONFIG_FAILURE';

export const GET_ALL_USER_FORMS_LIST_REQUEST = 'GET_ALL_USER_FORMS_LIST_REQUEST';
export const GET_ALL_USER_FORMS_LIST_SUCCESS = 'GET_ALL_USER_FORMS_LIST_SUCCESS';
export const GET_ALL_USER_FORMS_LIST_FAILURE = 'GET_ALL_USER_FORMS_LIST_FAILURE';