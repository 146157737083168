import { FormFieldTypes } from '../../../constants/common';

export const getBuilderTab = [
  {
    name: 'claims',
    label: 'Claims Builder',
    title: 'Claims Builder',
    hide: false,
  },
  {
    name: 'user-onboarding',
    label: 'User Onboarding Editor',
    title: 'User Onboarding Editor',
    hide: false,
  },
];

export const getFieldTypeAndDataType = (fieldType) => {
  switch (fieldType) {
    case 'NUM':
      return { fieldType: 'TXT', dataType: 'NUM' };
    case 'EML':
      return { fieldType: 'TXT', dataType: 'EML' };
    case 'MLX':
      return { fieldType: 'TXT', dataType: 'MLX' };
    default:
      return { fieldType };
  }
};

export const FormHeadCell = [
  {
    id: 'name',
    label: 'Claim Name',
  },
  {
    id: 'category',
    label: 'Category',
  },
  {
    id: 'fieldCount',
    label: 'Fields Count',
  },
];

export const BuilderModalFormFieldConfig = [
  {
    fieldLabel: 'Field Reference Name',
    placeholderText: 'Type here',
    fieldType: FormFieldTypes.SingleSelect,
    isRequired: true,
    fieldRefName: 'sysField',
  },
  {
    fieldLabel: 'Label',
    placeholderText: 'Type here',
    fieldType: FormFieldTypes.Text,
    isRequired: true,
    fieldRefName: 'fieldLabel',
  },
  {
    fieldLabel: 'Position',
    placeholderText: 'Type here',
    fieldType: FormFieldTypes.Text,
    dataType: FormFieldTypes.Number,
    isRequired: false,
    fieldRefName: 'positionOrder',
  },
  {
    fieldLabel: 'Field Size',
    placeholderText: 'Type here',
    fieldType: FormFieldTypes.SingleSelect,
    isRequired: true,
    fieldRefName: 'fieldSize',
  },
  {
    fieldLabel: 'Placeholder',
    placeholderText: 'Type here',
    fieldType: FormFieldTypes.Text,
    isRequired: false,
    fieldRefName: 'placeholderText',
  },
  {
    fieldLabel: 'Tool Tip Text',
    placeholderText: 'Type here',
    fieldType: FormFieldTypes.Text,
    isRequired: false,
    fieldRefName: 'tooltipText',
  },
  {
    fieldLabel: 'Unique Field Validation',
    placeholderText: 'Select Option',
    fieldType: FormFieldTypes.SingleSelect,
    isRequired: false,
    fieldRefName: 'option',
  },
  {
    fieldLabel: 'Error Message For Unique Validation',
    placeholderText: 'Type here',
    fieldType: FormFieldTypes.Text,
    isRequired: false,
    fieldRefName: 'errorMessageValidationOptions',
  },
  {
    fieldLabel: 'Max Length',
    placeholderText: 'Type here',
    fieldType: FormFieldTypes.Text,
    dataType: FormFieldTypes.Number,
    isRequired: false,
    fieldRefName: 'maxLength',
  },
  {
    fieldLabel: 'Rounded To',
    placeholderText: 'Select Option',
    fieldType: FormFieldTypes.SingleSelect,
    isRequired: false,
    fieldRefName: 'percisionNumber',
    isHidden: true,
  },
  {
    fieldLabel: 'Date Display Format',
    placeholderText: 'Select Option',
    fieldType: FormFieldTypes.SingleSelect,
    isRequired: false,
    fieldRefName: 'dateFormat',
    isHidden: true,
  },
  {
    fieldLabel: 'Time Between Two Claim (in minutes)',
    placeholderText: 'Type here',
    fieldType: FormFieldTypes.Text,
    dataType: FormFieldTypes.Number,
    isRequired: false,
    fieldRefName: 'timeInMinutes',
    isHidden: true,
  },
  {
    fieldLabel: 'Error Message For Time Validation',
    placeholderText: 'Type here',
    fieldType: FormFieldTypes.Text,
    isRequired: false,
    fieldRefName: 'errorMessage',
    isHidden: true,
  },
  {
    fieldLabel: 'Is Required',
    placeholderText: 'Type here',
    fieldType: FormFieldTypes.CheckBox,
    isRequired: false,
    fieldRefName: 'isRequired',
  },
  {
    fieldLabel: 'Is ReadOnly',
    placeholderText: 'Type here',
    fieldType: FormFieldTypes.CheckBox,
    isRequired: false,
    fieldRefName: 'isReadOnly',
  },
  {
    fieldLabel: 'Is Hidden',
    placeholderText: 'Type here',
    fieldType: FormFieldTypes.CheckBox,
    isRequired: false,
    fieldRefName: 'isHidden',
  },
  {
    fieldLabel: 'Thousand Separator',
    placeholderText: 'Type here',
    fieldType: FormFieldTypes.CheckBox,
    isRequired: false,
    fieldRefName: 'isCurrencyFormater',
    isHidden: true,
  },
  {
    fieldLabel: 'Remove Decimal',
    placeholderText: 'Type here',
    fieldType: FormFieldTypes.CheckBox,
    isRequired: false,
    fieldRefName: 'removeDecimal',
    isHidden: true,
  },
  {
    fieldLabel: 'To Uppercase',
    placeholderText: 'Type here',
    fieldType: FormFieldTypes.CheckBox,
    isRequired: false,
    fieldRefName: 'toUpperCase',
    isHidden: true,
  },
];

export const ApprovalsConfigs = [
  {
    fieldLabel: 'Select Approval Step',
    placeholderText: 'Type here',
    fieldType: FormFieldTypes.SingleSelect,
    isRequired: true,
    fieldRefName: 'approvalStep',
    fieldSize: 11,
  },
  {
    fieldLabel: 'Approver is able to edit existing records',
    placeholderText: 'Type here',
    fieldType: FormFieldTypes.CheckBox,
    isRequired: false,
    fieldRefName: 'approverEdit',
  },
];

export const ApprovalsConfigsField = {
  selectApprovalStep: 'approvalStep',
};

export const FieldSizeDropDown = [
  { id: 6, name: 6 },
  { id: 12, name: 12 },
];

export const BuilderFormConfig = [
  {
    fieldLabel: 'Claim Category',
    placeholderText: 'Select Category',
    fieldType: FormFieldTypes.SingleSelect,
    isRequired: false,
    fieldRefName: 'categoryId',
  },
  {
    fieldLabel: 'Claim Name',
    placeholderText: 'Type here',
    fieldType: FormFieldTypes.Text,
    isRequired: false,
    fieldRefName: 'name',
  },
  {
    fieldLabel: 'Maximum Claims Allowed',
    placeholderText: 'Type here',
    fieldType: FormFieldTypes.Text,
    dataType: FormFieldTypes.Number,
    isRequired: false,
    fieldRefName: 'maxClaimsAllowed',
  },
  {
    fieldLabel: 'Key Contact Persons',
    placeholderText: 'Select Key Contact Persons',
    fieldType: FormFieldTypes.MutliSelect,
    isRequired: false,
    fieldRefName: 'keyContactPersons',
  },
  {
    fieldLabel: 'Benefits Attached?',
    placeholderText: 'Benefits Attached?',
    fieldType: FormFieldTypes.CheckBox,
    isRequired: false,
    fieldRefName: 'isBenefitAttached',
  },
  {
    fieldLabel: 'Benefits',
    placeholderText: 'Select Benefit',
    fieldType: FormFieldTypes.SingleSelect,
    isRequired: false,
    fieldRefName: 'fkBenefitId',
  },
  {
    fieldLabel: 'Is Multi Department Form?',
    placeholderText: 'Is Multi Department Form?',
    fieldType: FormFieldTypes.CheckBox,
    isRequired: false,
    fieldRefName: 'isMultiDepartment',
  },
  {
    fieldLabel: 'Is Unergizer Form?',
    placeholderText: 'Is Unergizer Form?',
    fieldType: FormFieldTypes.CheckBox,
    isRequired: false,
    fieldRefName: 'isUnergizer',
  },
  {
    fieldLabel: 'Co-Payment Allowed ?',
    placeholderText: 'Co-payment Allowed ?',
    fieldType: FormFieldTypes.CheckBox,
    isRequired: false,
    fieldRefName: 'allowCopayment',
  },
  {
    fieldLabel: 'Payment Mode',
    placeholderText: 'Select Option',
    fieldType: FormFieldTypes.SingleSelect,
    isRequired: false,
    fieldRefName: 'paymentMode',
  },
  {
    fieldLabel: 'Form Access Condition',
    placeholderText: 'Type here',
    fieldType: FormFieldTypes.Text,
    dataType: FormFieldTypes.MultiLineText,
    isRequired: false,
    fieldRefName: 'formAccessCondition',
  },
];

export const builderFields = {
  categoryId: 'categoryId',
  name: 'name',
  minMinutesNextClaim: 'minMinutesNextClaim',
  benifitsAttached: 'fkBenefitId',
  isBenefitAttached: 'isBenefitAttached',
  keyContactPersons: 'keyContactPersons',
  paymentMode: 'paymentMode',
};

export const ClaimCategoryDropDown = [
  { id: 2, name: 'Expenses' },
  { id: 1, name: 'Benefits' },
];

export const FieldFormter = [
  { id: 1, name: '1 Decimal' },
  { id: 2, name: '2 Decimal' },
  { id: 3, name: '3 Decimal' },
];

export const DateFormter = [
  { id: 'DD/MM/YYYY', name: 'DD/MM/YYYY' },
  { id: 'DD-MM-YYYY', name: 'DD-MM-YYYY' },
  { id: 'YYYY/MM/DD', name: 'YYYY/MM/DD' },
  { id: 'YYYY-MM-DD', name: 'YYYY-MM-DD' },
  { id: 'DD/MMM/YYYY', name: 'DD/MMM/YYYY' },
];

export const PaymentMode = {
  PaymentDateThroughPayroll: 'PDTP',
  PaymentMonthThroughAccountsPayable: 'PMTAP',
  PaymentDateThroughApprove: 'PDTA',
};
