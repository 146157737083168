import API from './api';

export class FormBuilderService extends API {
  static baseURL = API.env.REACT_APP_BASE_API_LOCAL + '/form-builder/' || '';
  constructor() {
    super(FormBuilderService.baseURL);
  }
  getChildForm = async (moduleName) =>
    this.responsify(
      await this.instance.get('list-forms', { params: { moduleName } })
    );

  getFormConfig = async (moduleId) =>
    this.responsify(
      await this.instance.get('get-config', { params: { moduleId } })
    );

  postFormConfig = async (formConfig, formId) =>
    this.responsify(await this.instance.post('update', { formConfig, formId }));
}
