import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CircularLoader from '../../../../atoms/CircularLoader/circular-loader';
import {
  LoadingStatus,
  toastMessage,
  ToastThemes,
} from '../../../../constants/common';
import TabBar from '../../../../atoms/Tabs/TabBar';
import { showToast } from '../../../../utils/common.util';
import {
  stringSubstitute,
  ToastMessages,
} from '../../../OnBoarding/Organization/constants/onboarding.constants';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useOutletContext } from 'react-router-dom';
import UserOnboardingBuilder from './user-onboarding-builder';
import {
  getFormConfig,
  resetConfig,
} from '../../../../redux/user-onboarding-builder/action';
import { FormBuilderService } from '../../../../services/formBuilder';

const ManageUserOnboardingBuilderForm = () => {
  const [viewMode, setViewMode] = useOutletContext();
  const { currentUserRoleId } = useSelector((state) => state.main);
  const { claimData, list, requestProcessing } =
    useSelector((state) => state.userOnboardingBuilder) || {};
  const [loading, setLoading] = useState(-1);
  const [postLoading, setPostLoading] = useState(-1);
  const dispatch = useDispatch();
  const location = useLocation();
  const pathNames = location.pathname.split('/');
  const idForFormDetails = pathNames[pathNames.length - 1];

  useEffect(() => {
    if (currentUserRoleId) {
      if (!isNaN(idForFormDetails)) {
        setLoading(LoadingStatus.Loading);
        dispatch(getFormConfig(idForFormDetails))
          .then((res) => {
            setLoading(LoadingStatus.Success);
          })
          .catch(() => {
            setLoading(LoadingStatus.Failure);
          });
      } else {
        dispatch(resetConfig());
      }
    }
  }, [idForFormDetails, currentUserRoleId]);

  const handleFormSaveClick = async () => {
    setPostLoading(LoadingStatus.Loading);
    const newList = list.map((item) => {
      const {
        fieldSize,
        minDate,
        maxDate,
        disablePast,
        disableFuture,
        percisionNumber,
        dateFormat,
        timeInMinutes,
        errorMessage,
        option,
        errorMessageValidationOptions,
        shouldDisable,
        ...rest
      } = item;
      return {
        ...rest,
        fieldSize: fieldSize?.id,
        percisionNumber: percisionNumber?.id,
        dateFormat: dateFormat?.id,
        minMinutesNextClaim: { timeInMinutes, errorMessage },
        validationOption: { option: option?.id, errorMessageValidationOptions },
      };
    });
    await new FormBuilderService()
      .postFormConfig(newList, idForFormDetails)
      .then((res) => {
        dispatch(getFormConfig(idForFormDetails));
        showToast(
          ToastMessages.success.replace(stringSubstitute, toastMessage.success),
          ToastThemes.success
        );
        setPostLoading(LoadingStatus.Success);
      })
      .catch((err) => {
        showToast(err, ToastThemes.error);
        setPostLoading(LoadingStatus.Failure);
      });
  };
  const formTab = [
    {
      name: 'create-form',
      label: 'Manage Form',
      title: 'Manage Form',
      hide: false,
      panel: (
        <UserOnboardingBuilder
          list={list}
          handleFormSaveClick={handleFormSaveClick}
        />
      ),
    },
  ];

  return (
    <Box>
      <CircularLoader
        show={
          loading === LoadingStatus.Loading ||
          postLoading === LoadingStatus.Loading
        }
      />
      <TabBar tabPanels={formTab} searchTabs disableCreate />
    </Box>
  );
};

export default ManageUserOnboardingBuilderForm;
