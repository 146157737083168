import React, { useEffect, useState } from 'react';
import { TextField, Button, Paper, Typography, Box } from '@mui/material';
import Logo from '../../assets/VE_LOGO1.png';
import { CommonSevice } from '../../services/common';
import { showToast } from '../../utils/common.util';
import { LoadingStatus, ToastThemes } from '../../constants/common';
import CircularLoader from '../../atoms/CircularLoader/circular-loader';

const Subscribe = () => {
  const [formData, setFormData] = useState({
    tenantName: '',
    tenantSubdomain: '',
    adminName: '',
    email: '',
    adminMobileNumber: '',
    country: '',
    city: '',
  });
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const [otpVerified, setOtpVerified] = useState(false);
  const [resendTimer, setResendTimer] = useState(60);
  const [tenantProfileId, setTenantProfileId] = useState(null);
  const [loading, setLoading] = useState(-1);

  useEffect(() => {
    let timer;
    if (otpSent && resendTimer > 0) {
      timer = setInterval(() => {
        setResendTimer((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [otpSent, resendTimer]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleOtpChange = (index, value) => {
    if (/^[0-9]?$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value && index < 5) {
        document.getElementById(`otp-${index + 1}`).focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !otp[index] && index > 0) {
      document.getElementById(`otp-${index - 1}`).focus();
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(LoadingStatus.Loading);
    await new CommonSevice()
      .sendOTP(formData)
      .then((res) => {
        setTenantProfileId(res);
        showToast(
          'Success! An OTP has been sent to your email. Please check your inbox',
          ToastThemes.success
        );
        setOtpSent(true);
        setResendTimer(60);
        setLoading(LoadingStatus.Success);

        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: 'smooth',
        });
      })
      .catch((err) => {
        setLoading(LoadingStatus.Failure);
        showToast(
          err?.response?.data?.message || 'Something Went Wrong! Try Again',
          ToastThemes.error
        );
        throw err?.response?.data?.message;
      });
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    setLoading(LoadingStatus.Loading);
    const otpValue = otp.join('');
    await new CommonSevice()
      .verifyOTP({
        email: formData.email,
        otp: otpValue,
        tenantProfileId: tenantProfileId,
      })
      .then(() => {
        showToast('OTP Verified!', ToastThemes.success);
        setOtpVerified(true);
        setResendTimer(0);
        setFormData({
          tenantName: '',
          tenantSubdomain: '',
          adminName: '',
          email: '',
          adminMobileNumber: '',
          country: '',
          city: '',
        });
        setLoading(LoadingStatus.Success);
      })
      .catch((err) => {
        setLoading(LoadingStatus.Failure);
        showToast(
          err?.response?.data?.message || 'Invalid OTP.',
          ToastThemes.error
        );
        throw err?.response?.data?.message;
      });
  };

  const handleResendOtp = async () => {
    setLoading(LoadingStatus.Loading);
    await new CommonSevice()
      .sendOTP({ email: formData.email, existingTenantId: tenantProfileId })
      .then(() => {
        showToast(
          'Success! An OTP has been sent to your email. Please check your inbox',
          ToastThemes.success
        );
        setResendTimer(60);
        setLoading(LoadingStatus.Success);
      })
      .catch((err) => {
        setLoading(LoadingStatus.Failure);
        showToast(
          err?.response?.data?.message || 'Something Went Wrong! Try Again',
          ToastThemes.error
        );
        throw err?.response?.data?.message;
      });
  };

  return (
    <>
      <CircularLoader show={LoadingStatus.Loading === loading} />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        bgcolor="#f5f5f5"
        overflowY="auto"
        padding="20px"
      >
        <Paper
          elevation={3}
          style={{ padding: '30px', maxWidth: '600px', width: '100%' }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            marginBottom="20px"
          >
            <img
              src={Logo} // Replace with your logo URL
              alt="Logo"
              style={{ width: '100px', height: '100px', borderRadius: '50%' }}
            />
            <Typography
              variant="h5"
              align="center"
              style={{ marginTop: '10px' }}
            >
              Subscribe Now
            </Typography>
          </Box>
          <form onSubmit={handleFormSubmit}>
            <TextField
              fullWidth
              label="Organization Name"
              name="tenantName"
              value={formData.tenantName}
              onChange={handleInputChange}
              margin="normal"
              required
            />
            <TextField
              fullWidth
              label="Preferred Domain Name"
              name="tenantSubdomain"
              value={formData.tenantSubdomain}
              onChange={(e) => {
                const value = e.target.value;
                const regex = /^[a-zA-Z0-9-_]*$/;
                if (regex.test(value)) {
                  handleInputChange(e);
                }
              }}
              margin="normal"
              required
            />
            <TextField
              fullWidth
              label="Name"
              name="adminName"
              value={formData.adminName}
              onChange={handleInputChange}
              margin="normal"
              required
            />
            <TextField
              fullWidth
              label="Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleInputChange}
              margin="normal"
              required
            />
            <TextField
              fullWidth
              label="Mobile Number"
              name="adminMobileNumber"
              value={formData.adminMobileNumber}
              onChange={handleInputChange}
              margin="normal"
              required
            />
            <TextField
              fullWidth
              label="Country"
              name="country"
              value={formData.country}
              onChange={handleInputChange}
              margin="normal"
              required
            />
            <TextField
              fullWidth
              label="City"
              name="city"
              value={formData.city}
              onChange={handleInputChange}
              margin="normal"
            />
            <Box display="flex" justifyContent="center">
              <Button
                type="submit"
                variant="contained"
                style={{ marginTop: '20px' }}
              >
                Submit
              </Button>
            </Box>
          </form>
          {!otpVerified && otpSent && (
            <form onSubmit={handleOtpSubmit}>
              <Typography
                variant="h5"
                align="center"
                style={{ marginTop: '20px', marginBottom: '20px' }}
              >
                Enter OTP
              </Typography>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                marginBottom="20px"
                position="relative"
              >
                <Box display="flex" justifyContent="center" gap="10px">
                  {otp.map((digit, index) => (
                    <TextField
                      key={index}
                      id={`otp-${index}`}
                      value={digit}
                      onChange={(e) => handleOtpChange(index, e.target.value)}
                      onKeyDown={(e) => handleKeyDown(e, index)}
                      inputProps={{
                        maxLength: 1,
                        style: { textAlign: 'center' },
                      }}
                      style={{ width: '50px' }}
                    />
                  ))}
                </Box>
                <Typography
                  variant="body2"
                  style={{
                    position: 'absolute',
                    bottom: '-30px',
                    right: '125px',
                    cursor: resendTimer > 0 ? 'not-allowed' : 'pointer',
                    textDecoration: resendTimer > 0 ? 'none' : 'underline',
                    color: resendTimer > 0 ? 'gray' : 'blue',
                  }}
                  onClick={resendTimer === 0 ? handleResendOtp : null}
                  onMouseOver={(e) => {
                    if (resendTimer === 0) e.target.style.color = 'darkblue';
                  }}
                  onMouseOut={(e) => {
                    if (resendTimer === 0) e.target.style.color = 'blue';
                  }}
                >
                  Resend OTP {resendTimer > 0 ? `(${resendTimer}s)` : ''}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="center">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{ marginTop: '15px' }}
                >
                  Verify OTP
                </Button>
              </Box>
            </form>
          )}
        </Paper>
      </Box>
    </>
  );
};

export default Subscribe;
