import React, { useState } from 'react';
import FormEdit from '../../../OnBoarding/common/Form/FormEdit';
import ActionHeader from '../../../OnBoarding/Organization/ActionHeader/ActionHeader';
import CircularLoader from '../../../../atoms/CircularLoader/circular-loader';
import { Box } from '@mui/material';
import { LoadingStatus } from '../../../../constants/common';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import UserOnboardingModalView from './user-onboarding-modal-view';
import { deleteModalUser } from '../../../../redux/user-onboarding-builder/action';
import BuilderSidebar from '../builder-sidebar/builder-sidebar.component';

const UserOnboardingBuilder = (props) => {
    const {
        list,
        handleFormSaveClick,
      } = props;
    
      const [showModal, setShowModal] = useState(false);
      const dispatch = useDispatch();
      const navigate = useNavigate();
      const [modalData, setModalData] = useState({});
      const [configItemIndex, setConfigItemIndex] = useState(null);
    
      const handleEditButtonClick = (item, index) => {
        setModalData({ ...item });
        setShowModal(true);
        setConfigItemIndex(index);
      };
    
      const deleteClick = (index) => {
        dispatch(deleteModalUser(index));
      };
    
      const newList = list?.map((item) => {
        let newItem = { ...item };
    
        if (!item.isDisabled) {
          newItem.isDisabled = true;
        }
    
        if (item.fieldSize && item.fieldSize.id) {
          newItem.fieldSize = item.fieldSize.id;
        }
    
        return newItem;
      });
    
      const handleDiscard = () => {
        navigate(-1);
      };
    
      return (
        <Box>
          <Box mb={3} mx={-3} mt={-3}>
            <ActionHeader
              labelText={'New Form'}
              showDiscard
              showPublish
              showPublishModal={false}
              publishButtonText="Save"
              onPublishClick={handleFormSaveClick}
              onClickDiscard={handleDiscard}
              showSave={false}
            />
          </Box>
          <Box mt={2}>
            <FormEdit
              list={newList}
              button={true}
              editClick={handleEditButtonClick}
              deleteClick={deleteClick}
              formInfo={{}}
              hideFields={false}
            />
          </Box>
          <BuilderSidebar />
          {modalData && (
            <UserOnboardingModalView
              showModal={showModal}
              setShowModal={setShowModal}
              data={modalData}
              index={configItemIndex}
              isEdit={true}
              fieldType={modalData?.fieldType}
              dataType={modalData?.dataType}
              fieldSize={modalData?.fieldSize}
            />
          )}
        </Box>
      );
    };

export default UserOnboardingBuilder;